<template>
  <div class="my-3">
    <span
      class="title mb-3 d-inline-block"
      v-text="element.title"
    />

    <VSimpleTable dense>
      <template #default>
        <tbody>
          <tr
            v-for="(item, key, index) in element.info"
            :key="index"
          >
            <Fragment v-if="isArray(item) && item.length">
              <td
                colspan="2"
                class="pa-0"
              >
                <CMExpansionDetails
                  :title="startCase(key)"
                  :elements="item"
                />
              </td>
            </Fragment>

            <Fragment v-if="!isArray(item)">
              <td
                colspan=""
                class="text-left"
                v-text="startCase(key)"
              />
              <td
                class="text-left"
                v-text="item"
              />
            </Fragment>
          </tr>
        </tbody>
      </template>
    </VSimpleTable>
  </div>
</template>

<script>
import { startCase, isArray } from 'lodash'
import { Fragment } from 'vue-frag'

export default {
  name: 'MSInfoDetails',

  components: {
    CMExpansionDetails: () => import('@/components/Common/CMExpansionDetails.vue'),
    Fragment
  },

  props: {
    element: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    isArray () {
      return isArray
    },

    startCase () {
      return startCase
    }
  }
}
</script>
